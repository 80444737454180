@import '~/styles/utils';

.section {
  position: relative;
  z-index: 2;
  color: $color-foreground;
}

.halfSection {
  @include md {
    width: 50%;
    display: inline-block;
  }
}
